const GEN_SHARELINK_WINDOW_JS_URI = 'scripts/sharelink_window_4ai.15f90974.js';
const GEN_PWA_INSALL_JS_URI = 'scripts/pwa_install_4ai.8bd95f63.js';
const GEN_ONE_SUBMIT_JS_URI = 'scripts/one_submit_4ai.ea419889.js';
const GEN_CTA_CLICK_PHONE_JS_URI = 'scripts/cta_click_phone.c5144da0.js';
const GEN_SLIDER_JS_URI = 'scripts/slider.cbed5410.js';

const themePath = "/wp-content/themes/lebel-ensemble/dist/";

window.collections = {};

const SCRIPTS = [
    {
        'url' : GEN_SHARELINK_WINDOW_JS_URI,
        'sel' : '.share-links a',
        'col' : 'sharelinks'
    },
    {
        'url' : GEN_PWA_INSALL_JS_URI,
        'sel' : '.pwa-install-btn',
        'col' : 'addBtn'
    },
    {
        'url' : GEN_ONE_SUBMIT_JS_URI,
        'sel' : 'form',
        'col' : 'forms'
    },
    {
        'url' : GEN_CTA_CLICK_PHONE_JS_URI,
        'sel' : 'a[href^="tel:"]',
        'col' : 'clickPhone' 
    },
    {
        'url' : GEN_SLIDER_JS_URI,
        'sel' : '.slider-section',
        'col' : 'slider-horizontal' 
    },
    {
        'url' : GEN_SLIDER_JS_URI,
        'sel' : '[class^="slider-vertical"]',
        'col' : 'slider-vertical' 
    }
];

let selectors = [];
for(let script of SCRIPTS ) {
    selectors.push(script.sel);
}
const obj_present = Array.from(document.querySelectorAll( selectors.join(',') ) );

for(let script of SCRIPTS ) {
    if(Array.from) {
        collections[script.col] = Array.from(document.querySelectorAll(script.sel));
        if(collections[script.col].length > 0) {
            if(script.lazy) {
                let encours = false,
                    observer = new IntersectionObserver(function(entries) {
                    for (let j = 0, lg = entries.length; j < lg; j++) {
                        if (entries[j].intersectionRatio <= 0 || encours) return;
                        encours = true;
                        new loadExt([themePath+script.url]);
                        for (let k = 0, lgt = entries.length; k < lgt; k++) {
                            observer.unobserve(entries[k].target);
                        }
                    }
                }, obsOptions);

                for (let i = 0, l = collections[script.col].length; i < l; i++) {
                    observer.observe(collections[script.col][i]);
                }

            } else {
                new loadExt([themePath+script.url]);
            }
        }
    }
}

// FONCTIONS JS LEBEL ENSEMBLE
(function() {

    // Submit form filter realisations (select) device < tablet
    var selectCat = document.querySelector('[class*="filter_"] form select');
    if(selectCat != null && selectCat.length > 0) {
        selectCat.addEventListener("change", function(el){
            window.location.href = el.target.value;
        });
    }

})();
